export const routes = [
    {
      title: "Wnętrza",
      href: "/"
    },
    {
      title: "Ja",
      href: "/info"
    },
    {
      title: "Kontakt",
      href: "/kontakt"
    },
  ];