import { useState } from "react";
import { useRef } from "react";
import { useClickAway } from "react-use";
import { AnimatePresence, motion } from "framer-motion";
import { Squash as Hamburger } from "hamburger-react";
import { routes } from "../routes";
import { NavLink } from "react-router-dom";

export const NavMobile = () => {
    const [isOpen, setOpen] = useState(false);
    const ref = useRef(null);

    useClickAway(ref, () => setOpen(false));
  
    return (
      <div className="lg:hidden w-10">
        <div className="fixed left-0 z-20">
          <Hamburger toggled={isOpen} size={20} toggle={setOpen} />
          <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="fixed left-0 shadow-4xl right-0 p-5 pt-0 bg-neutral-100 border-b border-b-white/20 z-10"
            >
              <ul className="grid gap-2 divide-y divide-neutral-400">
                {routes.map((route, idx) => {

                  return (
                    <motion.li
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                        delay: 0.1 + idx / 10,
                      }}
                      key={route.title}
                      className="w-full p-[0.08rem]"
                    >
                      <NavLink
                        onClick={() => setOpen((prev) => !prev)}
                        className={({ isActive }) =>
                          isActive ? 'flex items-center justify-between w-full font-bold p-5' : 'flex items-center justify-between w-full font-normal p-5'
                        }
                        to={route.href}
                      >
                        <span className="flex gap-1 text-lg">{route.title}</span>
                      </NavLink>
                    </motion.li>
                  );
                })}
              </ul>
            </motion.div>
          )}
          </AnimatePresence>
        </div>
      </div>
    );
  };