import React, { useState } from "react";
import { RealisationModel } from "../realisations";
import FsLightbox from "fslightbox-react";
import SeeMore from "./SeeMore";
import RealisationDescriptionMobile from "./RealisationDescriptionMobile";
import RealisationSlider from "./RealisationSlider";

export default function RealisationMobile({
  realisation
}: {
  realisation: RealisationModel;
}) {
  const [toggler, setToggler] = useState(true);

  return (
    <div>
      <div
        className={`relative grid grid-cols-1 gap-5 pb-7`}
        key={realisation.nazwa}
      >
        <div onClick={() => setToggler(!toggler)}>
          <RealisationDescriptionMobile
            realisationDescription={realisation.opis}
            realisationName={realisation.nazwa}
          />
        </div>
        <RealisationSlider realisation={realisation} onImageClick={()=> {}}/>
        
        <div onClick={() => setToggler(!toggler)}>
          <SeeMore />
        </div>
        <FsLightbox
          toggler={toggler}
          sources={realisation.zdjecia.map((z) => "/realisations/" + z.url)}
        />
      </div>
    </div>
  );
}
