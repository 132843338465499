import React from "react";
import Logo from "./Logo";

export default function Loader(){

    return (
      <div className="flex items-center align-middle text-8xl h-screen loader-container">
        <div className="text-center w-screen  text-lg lg:text-2xl">
          <Logo />
        </div>
      </div>
    );
}