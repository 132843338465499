import React from "react";
import { Link } from "react-router-dom";

export default function Logo(){

    return (
      <Link to="/">
        <div>
          <span className="brand-prefix">Daniel Rusowicz&nbsp;</span>
          <span className="brand-sufix">wnętrza</span>
        </div>
      </Link>
    );
}