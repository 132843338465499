import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs: any[]) {
  return twMerge(clsx(inputs))
}

export function getHeight(isOneColumn:boolean) {
  return  isOneColumn ? "h-192" : "h-144";
}
export function getMaxHeight(isOneColumn:boolean) {
  return  isOneColumn ? "max-h-192" : "max-h-144";
}