import { RealisationModel } from "../realisations";
import RealisationDesktop from "./RealisationDesktop";
import RealisationMobile from "./RealisationMobile";

export default function Realisation({
  realisation
}: {
  realisation: RealisationModel;
}) {

  return (
    <>
      <div className="hidden lg:block">
        <RealisationDesktop
          realisation={realisation}
        />
      </div>
      <div className="lg:hidden">
        <RealisationMobile realisation={realisation}/>
      </div>
    </>
  );
}
