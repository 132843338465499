import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { MaxWidthWrapper } from './components/MaxWidthWrapper';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from './App';
import RealisationsPage from './pages/RealisationsPage';
import Contact from './pages/ContactPage';
import InfoPage from './pages/InfoPage';
import ErrorPage from './pages/ErrorPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage/>,
    children: [
      { path: "/", element: <RealisationsPage /> },
      {
        path: "/info",
        element: <InfoPage></InfoPage>
      },
      {
        path: "/kontakt",
        element: <Contact></Contact>
      },
      { path: "/5/*", element: <Contact /> }
    ]
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <MaxWidthWrapper>
          <RouterProvider router={router}></RouterProvider>
    </MaxWidthWrapper>
  </React.StrictMode>
);
