import { MoveRight } from "lucide-react";
import React from "react";

export default function SeeMore() {
  return (
    <div className="cursor-pointer hover:underline hover:text-slate-600 ">
      <span className="text-sm float-left">
        Zobacz więcej zdjęć &nbsp;
      </span>
      <MoveRight strokeWidth={1} />
    </div>
  );
}
