import './App.css';
import Menu from './components/Menu';
import { Outlet } from "react-router-dom";
import Loader from './components/Loader';
import { useState, useEffect } from 'react';
import React from 'react';
import { ChevronUpCircle } from 'lucide-react';
import Logo from './components/Logo';


function App() {

  const [isLoading, setIsLoading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);  


  const handleNavigation = (e: any) => {
    const window = e.currentTarget;
    if(0 < window.scrollY) 
    {
      setIsScrolled(true);
    }
    if(0 === window.scrollY)
    {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
      setTimeout(() => {
          setIsLoading(false);
      }, 2000);

      window.addEventListener("scroll", (e) => handleNavigation(e));
  }, [isLoading]);

  return (
    <>
      <div
        className={
          isLoading ? "block fixed top-0 left-0 z-50 bg-slate-50" : "hidden"
        }
      >
        <Loader />
      </div>
      <div>
        <div className='pt-6'>
          <Logo/>
        </div>
        <div className="flex py-4">
          <Menu />
          <div className={`flex-initial w-10/12`}>
            <Outlet />
          </div>
          {isScrolled && (
            <div
              className="fixed right-3 lg:right-10 bottom-3 lg:bottom-10"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <ChevronUpCircle strokeWidth={1} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default App;
