import React, { useState } from "react";
import { RealisationModel } from "../realisations";
import FsLightbox from "fslightbox-react";
import RealisationDescription from "./RealisationDescription";

import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Info } from "lucide-react";
import RealisationSlider from "./RealisationSlider";

export default function RealisationDesktop({
  realisation
}: {
  realisation: RealisationModel;
}) {
  const [toggler, setToggler] = useState(true);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div className="hidden lg:block mb-5">
      <h2 className="text-xl py-2 flex">
        {realisation.nazwa}
        <Info
        className="ml-auto"
          strokeWidth={1}
          size="1.125rem"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
      </h2>

      <div
        className={`relative grid grid-cols-1`}
        key={realisation.nazwa}
      >
        <RealisationSlider realisation={realisation} onImageClick={()=> {setToggler(!toggler)}}/>
        
        <div className="z-10" onClick={() => setToggler(!toggler)}
        style={{
          opacity: isHovering ? "1" : "0",
          transition: "all .3s",
          visibility: !isHovering ? "hidden" : "visible"
      }}
        >
          <RealisationDescription
            realisationName={realisation.nazwa}
            realisationDescription={realisation.opis}
            showMore={false}
          ></RealisationDescription>
        </div>

        <FsLightbox
          toggler={toggler}
          sources={realisation.zdjecia.map((z) => "/realisations/" + z.url)}
        />
      </div>
    </div>
  );
}
