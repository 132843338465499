import React from "react";
import SeeMore from "./SeeMore";

export interface ImageModel {
  src: string;
  realisationName: string;
  realiationCaption: string;
  caption: string;
  height?: string | undefined;
  width?: string | undefined;
}

export default function RealisationDescription({
  realisationName,
  realisationDescription,
  showMore,
}: {
  realisationName: string;
  realisationDescription: string;
  showMore: boolean;
}) {
  return (
    <div
      className={
        "realisation-description p-3 absolute bottom-0 top-0 right-0 left-0 tracking-wider max-h-192"
      }
    >
      <p className="font-normal">{realisationName}</p>
      <p className="pb-8">{realisationDescription}</p>
      {showMore && (
        <div className="absolute left-3 bottom-8 z-10">
          <SeeMore />
        </div>
      )}
    </div>
  );
}
