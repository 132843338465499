export interface ImageModel {
  src: string;
  realisationName: string;
  realiationCaption: string;
  caption: string;
  height?: string | undefined;
  width?: string | undefined;
}

export default function RealisationDescriptionMobile({
  realisationName,
  realisationDescription
}: {
  realisationName: string;
  realisationDescription: string;
}) {
  return (
    <div className={"py-3 tracking-wider relative"}>
      <p className="font-normal">{realisationName}</p>
      <p className="pb-1">{realisationDescription}</p>
    </div>
  );
}
