export interface RealisationModel {
  nazwa: string;
  opis: string;
  zdjecia: PhotoModel[];
}
export interface PhotoModel {
  "url": string;
  "alt":string;
}
export const REALISATIONS = [
 {
    nazwa: "Kancelaria",
    opis: "w Olsztynie, na ulicy Mazurskiej, w kamienicy z początku XX w. było sobie mieszkanie, które po spektakularnej metamorfozie i wielu przejściach stało się loftem z ciepłymi elementami drewna, akcentami koloru koralowego, zgaszonego szarością błękitu i z odkrytą piękną, zabytkową, oryginalną cegłą. Zwyczajne mieszkanie zmieniło się w stylową kancelarią radców prawnych.",
    zdjecia: [ 
      {"url":"PORTFOLIO_WARMINSKA/1.jpeg", "alt": "Opis" }, 
      {"url":"PORTFOLIO_WARMINSKA/2.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_WARMINSKA/3.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_WARMINSKA/4.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_WARMINSKA/5.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_WARMINSKA/6.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_WARMINSKA/7.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_WARMINSKA/8.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_WARMINSKA/9.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_WARMINSKA/10.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_WARMINSKA/11.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_WARMINSKA/12.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_WARMINSKA/13.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_WARMINSKA/14.jpeg", "alt": "Opis" }
   ]
 },
 {
  nazwa: "W bloku z wielkiej płyty",
  opis: "w Olsztynie, za to z przestrzenią za oknami, które pozwalają by do wnętrza wpadało mnóstwo słońca. To mieszkanie aż prosiło się o kolory:różowego nieba, intensywnego błękitu, groszkowej zieleni. Odważnie - graficznie, ciepło, słonecznie... kobieco!",
  zdjecia: [ 
    {"url":"PORTFOLIO_KORCZAKA/1.jpeg", "alt": "Opis" }, 
    {"url":"PORTFOLIO_KORCZAKA/2.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_KORCZAKA/3.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_KORCZAKA/4.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_KORCZAKA/5.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_KORCZAKA/6.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_KORCZAKA/7.jpeg", "alt": "Opis" }
 ]
  },
  {
    nazwa: "Apartament z widokiem",
    opis:"na Park Centralny. To Olsztyn, nie Nowy Jork! Z okien widać Tartak Raphaelsohnów, olsztyńską katedrę i ogrom zieleni parku, to wszystko zastaliśmy w samym centrum miasta. Sto dwadzieścia metrów kwadratowych podzielonych na cztery wąskie i dłuuuugie pokoje. Młoty poszły w ruch, jeden pokój zniknął, ale jaka w zamian pojawiła się przestrzeń! Warto było.",
    zdjecia: [ 
      {"url":"PORTFOLIO_KOSCIUSZKI/1.jpeg", "alt": "Opis" }, 
      {"url":"PORTFOLIO_KOSCIUSZKI/2.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_KOSCIUSZKI/3.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_KOSCIUSZKI/4.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_KOSCIUSZKI/5.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_KOSCIUSZKI/6.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_KOSCIUSZKI/7.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_KOSCIUSZKI/8.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_KOSCIUSZKI/9.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_KOSCIUSZKI/10.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_KOSCIUSZKI/11.jpeg", "alt": "Opis" },
      {"url":"PORTFOLIO_KOSCIUSZKI/12.jpeg", "alt": "Opis" },
   ]
 },
 {
  nazwa: "Warszawska kamienica",
  opis:"pamięta początek ubiegłego stulecia. Przetrwała w samym centrum, przy murach getta. Wysokie wnętrza, zaniedbane przez lata, prosiły się choć o namiastkę dawnej świetności. Zadbaliśmy o każdy element, przemyśleliśmy każdy detal i otrzymaliśmy powalający efekt, nieprawdaż?",
  zdjecia: [ 
    {"url":"PORTFOLIO_ZLOTA/1.jpeg", "alt": "Opis" }, 
    {"url":"PORTFOLIO_ZLOTA/2.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_ZLOTA/3.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_ZLOTA/4.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_ZLOTA/5.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_ZLOTA/6.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_ZLOTA/7.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_ZLOTA/8.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_ZLOTA/9.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_ZLOTA/10.jpeg", "alt": "Opis" },
 ]
},
{
  nazwa: "Monochromatyczne mieszkanie",
  opis: "ale wypełnione mnóstwem detali,rozrzeźbień, naturalnych akcentów, klasycznych form, obłych kształtów. Tu naprawdę wiele się dzieje, a jednak wszystko pięknie łączy się w całość. Jest przytulnie i praktycznie, bo sprytnie poprzestawiane ściany pozwoliły ukryć w przedpokoju pralkę, a w szafie w sypialni... garderobę, na czym bardzo zależało młodej właścicielce.",
  zdjecia: [ 
    {"url":"PORTFOLIO_GARNIZON/1.jpeg", "alt": "Opis" }, 
    {"url":"PORTFOLIO_GARNIZON/2.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_GARNIZON/3.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_GARNIZON/4.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_GARNIZON/5.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_GARNIZON/6.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_GARNIZON/7.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_GARNIZON/8.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_GARNIZON/9.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_GARNIZON/10.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_GARNIZON/11.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_GARNIZON/12.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_GARNIZON/13.jpeg", "alt": "Opis" },
    {"url":"PORTFOLIO_GARNIZON/14.jpeg", "alt": "Opis" },
 ]
},
] as RealisationModel[];