import { NavLink } from "react-router-dom";
import { NavMobile } from "./NavMobile";
import { routes } from "../routes";
import React from "react";

export default function Menu(){
    return (
      <>
        <nav className="flex-none w-1/12 text-xs hidden lg:block tracking-wider">
          <ul className="fixed">
            {routes.map((route, idx) => {
              return (
                <li key={idx} className="hover:underline hover:text-slate-600 pb-1">
                  <NavLink
                    to={route.href}
                    className={({ isActive, isPending }) =>
                      isPending ? "" : isActive ? "active" : ""
                    }
                    end
                  >
                    {route.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
        <NavMobile />
      </>
    );
}