import React from "react";

export default function InfoPage(){

    return (
      <div className="text-base lg:text-sm lg:relative">
        <img src="profilowe.jpeg" alt="Daniel Rusowicz projektant wnętrz - zdjęcie profilowe" className="max-w-full lg:max-w-lg pb-5"></img>
        <div className="lg:absolute left-96 pl-8 top-0 lg:text-right tracking-widest lg:translate-y-1/4 lg:font-normal">
        <p className="pb-10">Lubię to miejsce w domu, gdzie kuchnia przechodzi w salon a salon w
        kuchnię, część prywatna w publiczną, zwyczajna w świąteczną. <br/><br/>
        Lubię
        tworzyć wnętrza, które pasują do moich klientów i do otoczenia, do
        widoku za oknem i widoków na przyszłość. <br/><br/>
        Lubię projektować
        pomieszczenia, które dobrze wyglądają na planach i na co dzień, których
        życie nie zakłóca, a ubogaca. <br/><br/>
        Lubię wymyślać piękne i funkcjonalne
        miejsca do mieszkania i do pracy, do odpoczywania i wysiłku, na dni
        radosne i spokojne. <br/><br/>
        Lubię realizować swoje projekty, dobre pomysły
        ubierać w faktury, trwałość i widoczną jakość. <br/>
        </p>
        <p>Jestem projektantem
        wnętrz. Od lat tworzę miejsca, w których ludzie dobrze się czują.
        Projektuję i odpowiadam za realizację wnętrz domów, mieszkań i biur.</p>
        </div>
      </div>
    );
}