import React from 'react';
import { REALISATIONS } from '../realisations';
import Realisation from '../components/Realisation';
export default function RealisationsPage() {
  const realisations = REALISATIONS;

  return (
    <>
      {realisations.map((i) => (
            <Realisation realisation={i} key={i.nazwa}/>
      ))}

      <span
        className="hidden lg:block fixed right-10 top-10 ">
      </span>
    </>
  );
}